import { Box, Button, Link, Stack, Typography } from '@mui/material'
import VallyProWesleyImage from '../../components/images/VallyProWesleyImage.jpg'
import VallyProWesleyTripImage from '../../components/images/VallyProTripDetailImage.jpg'
import useMediaQuery from '@mui/material/useMediaQuery'
import { palette } from '../../myTheme'

export default function VallyProPage() {
    const isScreenSmall = useMediaQuery('(max-width: 900px)')
    return (
        <Box margin={isScreenSmall ? 6 : 12}>
            <Stack spacing={8}>
                <Stack
                    direction='row'
                    style={{
                        paddingBottom: 8,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography fontWeight={800} fontSize={isScreenSmall ? 18 : 70}>
                        VallyPro: Booking Solution
                    </Typography>
                    <Button
                        style={{
                            marginTop: isScreenSmall ? 0 : 13,
                            maxHeight: isScreenSmall ? 40 : 80,
                            fontSize: isScreenSmall ? 16 : 30,
                        }}
                        onClick={() => window.history.back()}
                    >
                        Back
                    </Button>
                </Stack>
                <Typography style={{ textAlign: isScreenSmall ? 'center' : 'start' }}
                    fontStyle={{ color: palette.white }}
                    fontWeight={400}
                    fontSize={isScreenSmall ? 14 : 20}>
                    This is the customer facing booking site for Wesley Hodges which is one of Vally's clients. I was in charge of this redesign and it allows for clients like Wesley Hodges to change their booking page with colors, fonts, and which of their trips are being highlighted. {' '}
                    <Link height={isScreenSmall ? 40 : 80} href={'https://book.vallypro.com/p/wesley-hodges'}>
                        <span
                            style={{ textAlign: isScreenSmall ? 'center' : 'start' }}
                            fontStyle={{ color: palette.white }}
                            fontWeight={400}
                            fontSize={isScreenSmall ? 14 : 20}
                        >
                            https://book.vallypro.com/p/wesley-hodges
                        </span>
                    </Link>
                </Typography>
                <Stack direction={'column'}>
                    <Box
                        component='img'
                        sx={{
                            display: 'flex',
                            placeItems: 'center',
                            placeContent: 'center',
                            width: 'auto',
                            height: '650px',
                            justifyContent: 'space-evenly',
                            borderRadius: '15px',
                            marginTop: '20px',
                            marginBottom: '20px',
                        }}
                        alt='app icon'
                        src={VallyProWesleyImage}
                    />
                    <Box
                        component='img'
                        sx={{
                            display: 'flex',
                            placeItems: 'center',
                            placeContent: 'center',
                            width: 'auto',
                            height: '700px',
                            justifyContent: 'space-evenly',
                            borderRadius: '15px',
                            marginTop: '20px',
                            marginBottom: '20px',
                        }}
                        alt='app icon'
                        src={VallyProWesleyTripImage}
                    />
                </Stack>
                <Typography
                    style={{ textAlign: isScreenSmall ? 'center' : 'start' }}
                    fontStyle={{ color: palette.white }}
                    fontWeight={400}
                    fontSize={isScreenSmall ? 14 : 20}
                >
                    Vally's online booking engine provides robust scheduling management for simple and complex operations alike. The client facing side of this is internal only and not pubclicly available but the customer facing booking site is. {' '}
                </Typography>
            </Stack>
        </Box>
    )
}
